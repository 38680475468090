import React, { useEffect, useContext, useState, useRef } from 'react'
import { Container, Flex, Box, IconButton,Text, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import FormInput3 from '@solid-ui-components/ContentForm/FormInput3'
import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import './styles.css'

import { setKtTokenSifre, getKtLogin, logOut } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import {  AiOutlineUser, AiOutlineUserAdd, AiOutlineUserSwitch, AiOutlineUsergroupAdd, AiOutlineUsergroupDelete } from "react-icons/ai";



const styles = {
  combo: {
    p: 0.4,
    pl: 3,
    flex: [`100%`, null, null, 1],
    border: 'transparent'
  },

  bxStyleAd: {
    width: '30%',
    px: [1]
  },

  bxStyleBirim: {
    width: '25%',
    px: [2]
  },
  wrapper1: {
    flex: [`100%`, null, null, 1],
    minWidth: 450,
    maxWidth: [`none`, null, null, 450],
    p: 2
  },
  wrapper2: {
    flex: [`100%`, null, null, 1],
    minWidth: 1000,
    maxWidth: [`none`, null, null, 1000],
    p: 2
  },
  card: {
    overflow: `hidden`,
    margin: "15px"
  },
  content: {
    alignItems: `stretch`,
    flexDirection: [`row`, null, null, `column`],
    height: `full`
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4
  },
  footerWrapper: {
    alignItems: `center`
  },
  postInfo: {
    flex: 1,
    flexWrap: `wrap`,
    justifyContent: `space-between`,
    color: `omega`,
    ml: 3
  },
  imageWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: `full`
  },
  image: {
    display: [`none`, `block`],
    height: `full`,
    bg: `omegaLighter`,
    borderRadius: `default`,
    borderWidth: 0,
    minHeight: `11rem`,
    div: {
      p: `0 !important`
    }
  },
  avatar: {
    size: 42,
    bg: `omegaLighter`,
    borderRadius: `full`,
    borderStyle: `solid`,
    borderWidth: `md`,
    borderColor: `omegaLighter`,
    boxSizing: `content-box`,
    img: {
      objectPosition: 'top center !important'
    }
  },

  textVisible: {
    visible: 'false',
  },


  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },
  close: {

    position: `absolute`,
    // top: [3, null, -5],
    // left: [2, null, null, -5],
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    width: 50,
    height: 50

  },
  wrappermlz: {
    p: 0.5
  }
}



const Profilim = ({ ...props }) => {

  const { handleSubmit, submitting, success } = useForm()
  const { formValues, setFormValues } = useContext(FormContext)


  const [btnID, setbtnID] = useState("");
  const formId = "frmProfil"
  const [dt, setDt] = useState(null);
  const [profil, setProfil] = useState(null);
  const [profilGenelMesaj, setprofilGenelMesaj] = useState("");
  const [faturaGenelMesaj, setfaturaGenelMesaj] = useState("");




  const [ldg, setLdg] = useState(false);
  


  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
        profilDataGetir()

      } else {
        logOut();
        setDt(null);
        setLdg(true);
      }
    }
  }, []);


  function profilDataGetir() {
    setDt(null);
    httpServiceToken.post("Locked/ProfilBilgileri", {}).then((res) => {
      if (res.DogruMu) {
        setDt(res.Data);
      } else {
        setDt(null);
      }
      setLdg(true);
    }).catch((err) => {
      setDt(null);
      setLdg(true);
    });
  }


  useEffect(() => {
    return () =>
      success !== undefined &&
      submitting === false &&
      setFormValues({
        ...formValues,
        [formId]: {}
      })
   
  }, [success, submitting, setFormValues])

  const onChange = e => {
    setFormValues({
      ...formValues,
      [formId]: {
        ...formValues?.[formId],
        [e.target.name]: e.target.value
      }

    })
  }






  const onSubmit = async e => {
    e.preventDefault();
    setprofilGenelMesaj("");
    setfaturaGenelMesaj("");
    let yeniData = {
      AktifUser: {
        AdSoyad: document.getElementById("txtAdSoyad")?.value,
        Mail: document.getElementById("txtMail")?.value,
        MailKodu: document.getElementById("txtMailDogrulama")?.value,
        Telefon: document.getElementById("txtTelefon")?.value,
        TelefonKodu: document.getElementById("txtTelefonDogrulama")?.value,
      },
      FaturaBilgi: {
        SirketAdi: document.getElementById("txtSirketAdi")?.value,
        VergiDairesi: document.getElementById("txtVergiDairesi")?.value,
        VergiNo: document.getElementById("txtVergiNo")?.value,
        Adres: document.getElementById("txtAdres")?.value,
        BilgilerGecerliMi: dt.FaturaBilgi?.BilgilerGecerliMi
      },
      AnaKullaniciMi: dt.AnaKullaniciMi,
      Kullanicilar: dt.Kullanicilar
    };




    if (btnID.includes("btnUs")) {
      var usId = btnID.split("-")[1];
      var ind = btnID.split("-")[3];

      let davetEdilecekUser = {
        Id: usId,
        AdSoyad: document.getElementById("txtUsAdSoyad" + ind)?.value,
        Mail: document.getElementById("txtUsMail" + ind)?.value,
        Telefon: document.getElementById("txtUsTelefon" + ind)?.value,
      }

      try {
        if (window != undefined) {
          document.getElementById("txtUsAdSoyad" + ind).value = "";
          document.getElementById("txtUsMail" + ind).value = "";
          document.getElementById("txtUsTelefon" + ind).value = "";
        }
      } catch (error) {

      }

      var Secili = btnID.split("-")[2];

      if (Secili === 'True') {
        httpServiceToken.post("Locked/KullaniciDavetEt", { params: davetEdilecekUser }).then((res) => {
          if (res.DogruMu) {
            profilDataGetir();
          } else {
            yeniData.Kullanicilar.map((data) => {
              if (data.Id == res.Data.Id) {
                data.AdSoyad = res.Data.AdSoyad;
                data.AdSoyadValidate = res.Data.AdSoyadValidate;
                data.DavetKabulEdildiMi = res.Data.DavetKabulEdildiMi;
                data.Mail = res.Data.Mail;
                data.MailDogruMu = res.Data.MailDogruMu;
                data.MailValidate = res.Data.MailValidate;
                data.Telefon = res.Data.Telefon;
                data.TelefonDogruMu = res.Data.TelefonDogruMu;
                data.TelefonValidate = res.Data.TelefonValidate;
              }
            });
            setDt(yeniData);
          }
        }).catch((err) => {
          //setProfil(null);
        });
      } else {
        httpServiceToken.post("Locked/KullaniciCikar", { params: davetEdilecekUser }).then((res) => {
          if (res.DogruMu) {

          } else {

          }
          profilDataGetir();

        }).catch((err) => {

        });
      }
    }
    if (btnID == "btnProfilKaydet") {

      httpServiceToken.post("Locked/ProfilKaydet", { params: yeniData.AktifUser }).then((res) => {
        if (res.DogruMu) {
          setProfil(res.Data);
          // setDt(res.Data);
        } else {
          setProfil(null);
        }
      }).catch((err) => {
        setProfil(null);
      });
    }

    if (btnID == "btnProfilOnayla") {

      yeniData.AktifUser.Mail = profil.Mail;
      yeniData.AktifUser.Telefon = profil.Telefon;
      httpServiceToken.post("Locked/ProfilDegisiklikOnayla", { params: yeniData.AktifUser }).then((res) => {
        if (res.DogruMu) {
          if (res.Data.GuncellemeOkMi == true) {
            setDt(yeniData);
            setProfil(null);
            setprofilGenelMesaj("Güncelleme işlemi başarıyla tamamlandı");
          } else {
            setProfil(res.Data);
          }
        } else {
          setProfil(null);
          setprofilGenelMesaj(res.Mesaj);
        }
      }).catch((err) => {
        setProfil(null);
        setprofilGenelMesaj("Genel bir hata oluştu.Lütfen tekrar deneyiniz");
      });
    }

    if (btnID == "btnFaturaKaydet") {
      httpServiceToken.post("Locked/FaturaKaydet", { params: yeniData.FaturaBilgi }).then((res) => {
        if (res.DogruMu) {
          yeniData.FaturaBilgi.BilgilerGecerliMi = res.Data.BilgilerGecerliMi;
          setDt(yeniData);
          setfaturaGenelMesaj(res.Mesaj);
        } else {
          setfaturaGenelMesaj(res.Mesaj);
        }
      }).catch((err) => {
        setfaturaGenelMesaj("Genel bir hata oluştu.Lütfen tekrar deneyiniz");
      });
    }

  }




  return (
    <Container>

      {ldg == false ? <div className='dv-l'>
        <Divider space="3" />
        <img src={loadsGif} alt="online box die cutting loading" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />
      </div> :
        <form
          onSubmit={onSubmit}
          method='POST'
        >
          {dt && (
            <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
              <Box sx={styles.wrapper1}>
                <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                  <Text as='h3' color='black'>
                    Profil Bilgileri
                  </Text> </Box>
                <ContentContainer
                  content={{
                    variant: "cards.interactive",
                  }}
                  variant='cards.interactive'
                  sx={styles.card} >

                  <Box sx={{ margin: "10px" }}>
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      <FormInput3
                        {...props}
                        onChange={onChange}
                        name={"txtAdSoyad"}
                        id={`txtAdSoyad`}
                        value={formValues?.[formId]?.["txtAdSoyad"] || dt.AktifUser?.AdSoyad || undefined}
                        placeholder="Ad Soyad"
                      />
                      {profil?.AdSoyadValidate != null && profil?.AdSoyadValidate != "" ?
                        <Text sx={{ m: 15 }} color="red">  {profil?.AdSoyadValidate}</Text> : <></>
                      }

                    </Box>

                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      {profil?.MailDogruMu == true ? <></> : <FormInput3
                        {...props}
                        onChange={onChange}
                        name={"txtMail"}
                        id={`txtMail`}
                        value={formValues?.[formId]?.["txtMail"] || dt.AktifUser?.Mail || undefined}
                        placeholder="Mail"
                      />
                      }
                      {profil != null ?
                        profil.MailDogruMu == false ? (
                          <Text sx={{ m: 15 }} color="red">{profil.MailValidate}</Text>
                        ) : profil.MailDogruMu == true ?
                          (
                            <div>
                              <Text sx={{ m: 15 }} color="alpha" > {profil.MailValidate}</Text>
                              <FormInput3
                                {...props}
                                onChange={onChange}
                                name={"txtMailDogrulama"}
                                id={`txtMailDogrulama`}
                                value={formValues?.[formId]?.["txtMailDogrulama"] || undefined}
                                placeholder="Mail Doğrulama Kodu"
                              />
                            </div>
                          ) :
                          (<></>)
                        : (<></>)
                      }

                    </Box>

                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      {profil?.TelefonDogruMu == true ? <></> : <FormInput3
                        {...props}
                        onChange={onChange}
                        name={"txtTelefon"}
                        id={`txtTelefon`}
                        value={formValues?.[formId]?.["txtTelefon"] || dt.AktifUser?.Telefon || undefined}
                        placeholder="Telefon"
                      />
                      }

                      {profil != null ?
                        profil.TelefonDogruMu == false ? (
                          <Text sx={{ m: 15 }} color="red">{profil.TelefonValidate}</Text>
                        ) : profil.TelefonDogruMu == true ?
                          (
                            <div>
                              <Text sx={{ m: 15 }} color="alpha" >{profil.TelefonValidate}</Text>
                              <FormInput3
                                {...props}
                                onChange={onChange}
                                name={"txtTelefonDogrulama"}
                                id={`txtTelefonDogrulama`}
                                value={formValues?.[formId]?.["txtTelefonDogrulama"] || undefined}
                                placeholder="Telefon Doğrulama Kodu"
                              />
                            </div>
                          ) :
                          (<></>)
                        : (<></>)
                      }
                    </Box>
                    <Divider space="3" />
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      {profilGenelMesaj != null && profilGenelMesaj != "" ?
                        <Text color="alpha">{profilGenelMesaj}</Text> : <></>
                      }
                      <div className="profil-btn">
                        {
                          profil != null && profil.MailDogruMu != false && profil.TelefonDogruMu != false ?
                            (<Button type="submit"
                              id={"btnProfilOnayla"}
                              aria-label="profil değiştir"
                              index={3}
                              onClick={e => setbtnID(e.target.id)}
                            >
                              Değişikliği Onayla
                            </Button>) :
                            <Button type="submit"
                              id={"btnProfilKaydet"}
                              aria-label="profil kaydet ambalaj firması"
                              index={3}
                              onClick={e => setbtnID(e.target.id)}
                            >
                              Kaydet
                            </Button>
                        }
                      </div>
                    </Box>
                    <Divider space="3" />
                  </Box>
                </ContentContainer>
              </Box>
              {dt.AnaKullaniciMi == true && (
                <>
                  <Box sx={styles.wrapper1}>
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      <Text as='h3' color='black'>
                        Fatura Bilgileri
                      </Text> </Box>
                    <ContentContainer
                      content={{
                        variant: "cards.interactive",
                      }}
                      variant='cards.interactive'
                      sx={styles.card} >

                      <Box sx={{ margin: "10px" }}>
                        <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                          <FormInput3
                            {...props}
                            onChange={onChange}
                            name={"txtSirketAdi"}
                            id={`txtSirketAdi`}
                            value={formValues?.[formId]?.["txtSirketAdi"] || dt.FaturaBilgi?.SirketAdi || undefined}
                            placeholder="Şirket Adı"
                          />
                        </Box>

                        <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                          <FormInput3
                            {...props}
                            onChange={onChange}
                            name={"txtAdres"}
                            id={`txtAdres`}
                            value={formValues?.[formId]?.["txtAdres"] || dt.FaturaBilgi?.Adres || undefined}
                            placeholder="Adres"
                          />
                        </Box>

                        <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                          <FormInput3
                            {...props}
                            onChange={onChange}
                            name={"txtVergiDairesi"}
                            id={`txtVergiDairesi`}
                            value={formValues?.[formId]?.["txtVergiDairesi"] || dt.FaturaBilgi?.VergiDairesi || undefined}
                            placeholder="Vergi Dairesi"
                          />
                        </Box>

                        <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                          <FormInput3
                            {...props}
                            onChange={onChange}
                            name={"txtVergiNo"}
                            id={`txtVergiNo`}
                            value={formValues?.[formId]?.["txtVergiNo"] || dt.FaturaBilgi?.VergiNo || undefined}
                            placeholder="Vergi/TC No"
                          />
                        </Box>
                        <Divider space="3" />
                        <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                          {faturaGenelMesaj != null && faturaGenelMesaj != "" ?
                            <Text color="alpha">{faturaGenelMesaj}</Text> : <></>
                          }
                          <div className="profil-btn">
                            <Button type="submit"
                              id={"btnFaturaKaydet"} index={3}
                              aria-label="fatura bilgisi kaydet"
                              onClick={e => setbtnID(e.target.id)}
                            >
                              Kaydet
                            </Button>
                          </div>
                        </Box>
                        <Divider space="3" />
                      </Box>
                    </ContentContainer>
                    {dt.FaturaBilgi == null || dt.FaturaBilgi?.BilgilerGecerliMi == false ?
                      (<Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                        <Text key={`lblbaslik${2}`} as='span' color='red' sx={{ fontSize: "13px" }} >
                          Dikkat! Fatura bilgileriniz geçerli değildir.
                        </Text>
                        <br />
                        <Text key={`lblbaslik${2}`} as='span' color='red' sx={{ fontSize: "13px" }} >
                          Lisans paketi satın aldığınızda 7 gün içerisinde fatura bilgileri girilmediği taktirde otomatik olarak muhtelif cari faturası kesilip ibraz edileceğinden geçmiş tarihli fatura kesilemez
                        </Text>
                      </Box>) : <></>}
                  </Box>

                  <Box sx={styles.wrapper2}>
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      <Text as='h3' color='black'>
                        Ekibimdeki Kullanıcılar
                      </Text> </Box>
                    <ContentContainer
                      content={{
                        variant: "cards.interactive",
                      }}
                      variant='cards.interactive'
                      sx={styles.card} >

                      <Box sx={{ margin: "10px" }}>

                        {dt.Kullanicilar && dt.Kullanicilar.map(
                          ({ AdSoyad, Mail, Telefon, Id, DavetKabulEdildiMi, TelefonDogruMu, MailValidate, MailDogruMu, TelefonValidate, AdSoyadValidate }, ind) => (
                            <Box key={`lblUsers${ind}`} >
                              <ContentContainer
                                content={{
                                  variant: "cards.interactive",
                                }}
                                variant='cards.interactive'
                                sx={styles.card} >
                                <Flex
                                  sx={{
                                    alignItems: `start`,
                                    justifyContent: `start`
                                  }}  >

                                  <Box sx={{ flex: 2, py: [2, 2], px: [3, 3] }}>

                                    <FormInput3
                                      {...props}
                                      onChange={onChange}
                                      name={"txtUsAdSoyad" + ind}
                                      id={"txtUsAdSoyad" + ind}
                                      value={formValues?.[formId]?.["txtUsAdSoyad" + ind] || AdSoyad || undefined}
                                      placeholder="Ad Soyad"
                                    />
                                    {AdSoyadValidate != null && AdSoyadValidate != "" ?
                                      <Text sx={{ m: 15 }} color="red">  {AdSoyadValidate}</Text> : <></>
                                    }
                                  </Box>
                                  <Box sx={{ flex: 2, py: [2, 2], px: [3, 3] }}>

                                    <FormInput3
                                      {...props}
                                      onChange={onChange}
                                      name={"txtUsMail" + ind}
                                      id={"txtUsMail" + ind}
                                      value={formValues?.[formId]?.["txtUsMail" + ind] || Mail || undefined}
                                      placeholder="Mail ile Davet Et"
                                    />
                                    {
                                      MailDogruMu == false ? (
                                        <Text sx={{ m: 15 }} color="red">{MailValidate}</Text>
                                      ) : MailDogruMu == true ?
                                        (
                                          <Text sx={{ m: 15 }} color="alpha" >{MailValidate}</Text>
                                        ) :
                                        (<></>)

                                    }
                                  </Box>
                                  <Box sx={{ flex: 2, py: [2, 2], px: [3, 3] }}>
                                    <FormInput3
                                      {...props}
                                      onChange={onChange}
                                      name={"txtUsTelefon" + ind}
                                      id={"txtUsTelefon" + ind}
                                      value={formValues?.[formId]?.["txtUsTelefon" + ind] || Telefon || undefined}
                                      placeholder="Sms ile Davet Et"
                                    />
                                    {
                                      TelefonDogruMu == false ? (
                                        <Text sx={{ m: 15 }} color="red">{TelefonValidate}</Text>
                                      ) : TelefonDogruMu == true ?
                                        (
                                          <Text sx={{ m: 15 }} color="alpha" >{TelefonValidate}</Text>
                                        ) :
                                        (<></>)

                                    }
                                  </Box>
                                  <Box sx={{ flex: 1, py: [1, 1], px: [1, 1] }}>
                                    {DavetKabulEdildiMi == null ?
                                      <Box sx={{ textAlign: "center", color: "grey" }} >
                                        <AiOutlineUser size={18} color="grey" />
                                        <br /> Boş Davetiye</Box> :
                                      DavetKabulEdildiMi == true ?
                                        <Box sx={{ textAlign: "center", color: "green" }} >
                                          <AiOutlineUserAdd size={18} color="green" />
                                          <br /> Kabul Edildi</Box>
                                        :
                                        DavetKabulEdildiMi == false ?
                                          <Box sx={{ textAlign: "center", color: "blue" }} >
                                            <AiOutlineUserSwitch size={18} color="blue" />
                                            <br /> Beklemede</Box>
                                          : <></>
                                    }
                                  </Box>
                                  <Box sx={{ flex: 1, py: [1, 1], px: [1, 1] }}>
                                    {DavetKabulEdildiMi == null ?
                                      <IconButton
                                        type="submit"
                                        id={"btnUsAdd-" + Id + "-True-" + ind}
                                        onClick={e => setbtnID("btnUsAdd-" + Id + "-True-" + ind)}
                                        aria-label='Davet Et'
                                        autoFocus
                                        size="large"
                                      >
                                        <Container><AiOutlineUsergroupAdd size={21} /> <Text>  Davet Et</Text></Container> :
                                      </IconButton> :
                                      <>
                                        <IconButton
                                          type="submit"
                                          id={"btnUsRemove-" + Id + "-False-" + ind}
                                          onClick={e => setbtnID("btnUsRemove-" + Id + "-False-" + ind)}
                                          aria-label='Listeden Çıkar'
                                          autoFocus
                                          size="large"
                                        >
                                          <Container> <AiOutlineUsergroupDelete color='red' size={21} /> <Text color='red'>Listeden Çıkar</Text> </Container>
                                        </IconButton> </>
                                    }

                                  </Box>
                                </Flex>
                              </ContentContainer>
                            </Box>
                          ))}
                      </Box>
                    </ContentContainer>
                    <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
                      <Text as='span' color='grey' sx={{ fontSize: "13px" }} >
                        Lisans paketinizden yararlanabilecek toplam {dt.AktifUser.KullaniciHakki} kullanıcı hakkınız bulunmaktadır. Daha fazla kullanıcı eklemek için lütfen bilgi@kututasarimi.com adresinden bizimle iletişime geçiniz
                      </Text>
                      <Text as='h5' color='grey' sx={{ fontSize: "13px" }} >
                        Not: Bu özellik firma içi kullanıcılar için tasarlanmıştır. Paket kapsamındaki tüm özelliklerden, yukarıdaki kullanıcılarla ortak yararlandığınızı unutmayınız. Listeden çıkarılan kullanıcıların sisteminize erişimi mümkün değildir.
                      </Text>
                    </Box>
                  </Box>
                </>
              )
              }
            </Flex>
          )}
        </form >
      }
    </Container >
  )
}

export default WithDefaultContent(Profilim)


